import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import VueMaterial  from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import vuetify from './plugins/vuetify'

Vue.use(VueMaterial)
Vue.config.devtools = true;


new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
