<template>
  <v-app>
    <div id="app">
      <h1 style="color: mediumblue">VIT Semester Subject Marks Calculator</h1><br>
      <div class="InputData">
        <v-text-field
            label="Presentation Marks(100)"
            v-model="marks.pres" type="number"
            oninput="if (this.value > 100) this.value = 100; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <v-text-field
            label="Group Discussion(100)"
            v-model="marks.gd" type="number"
            oninput="if (this.value > 100) this.value = 100; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <v-text-field
            label="Lab Assessment(100)"
            v-model="marks.lab" type="number"
            oninput="if (this.value > 100) this.value = 100; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <v-text-field
            label="Home Assignment(100)"
            v-model="marks.ha" type="number"
            oninput="if (this.value > 100) this.value = 100; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <v-text-field
            label="Course Project(100)"
            v-model="marks.cp" type="number"
            oninput="if (this.value > 100) this.value = 100; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <v-text-field
            label="Viva(100)"
            v-model="marks.cvv" type="number"
            oninput="if (this.value > 100) this.value = 100; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <v-text-field
            label="MSE(30)"
            v-model="marks.mse" type="number"
            oninput="if (this.value > 30) this.value = 30; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <v-text-field
            label="ESE(30)"
            v-model="marks.ese" type="number"
            oninput="if (this.value > 30) this.value = 30; if (this.value < 0) this.value = 0"
        ></v-text-field>
        <h1 style="font-weight: bold; color: red">Your Total Marks Are : {{ total }}</h1>
      </div>
      <br>
      <v-btn
          color="primary"
          elevation="11"
          v-on:click="dialog = true"
      >Save Locally</v-btn>
      <br><br>
      <v-btn
          color="primary"
          elevation="11"
          v-on:click="copyToClipboard"
      >Copy shareable link</v-btn>
      <br><br>
      <v-card
          class="mx-auto"
          max-width="300"
          tile
      >
        <v-list>
          <v-subheader style="font-size: x-large !important; font-weight: bold !important; ">Saved Marks</v-subheader>
          <v-list-item-group
              v-model="selectedItem"
              color="primary"
          >
            <v-list-item
                v-for="(item, i) in items"
                :key="i"
            >
              <v-list-item-icon v-on:click="loadSubject(i)">
                <v-icon v-text="item.frontIcon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon  v-on:click="deleteSubject(i)">
                <v-icon v-text="item.backIcon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <h2><br>Made by <a href="https://github.com/MrMalfunction" style="color: crimson;">Amol Bohora<img
          src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" style="max-width: 30px;"
          alt="Github Logo"></a></h2>
    </div>
    <!-- Subject Name Dialog -->
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          max-width="600px"
      >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Subject Name</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        label="Subject Name"
                        required
                        :rules="subjectRule"
                        v-model="subjectName"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small><br>
              <small>Will Overwrite old marks of same subject</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <div class="text-center">

      <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      valid: true,
      marks:{
        pres: '', gd: '', lab: '', ha: '', cp: '', cvv: '', mse: '', ese: '',
      },
      dialog: false,
      subjectName: '',
      subjectRule: [
        v => !!v || 'Name is required',
      ],
      selectedItem: -1,
      items: [
      ],
      rawData: {},
      snackbar: false,
      text: '',
      timeout: 2000,
    }
  },
  computed: {
    total: function () {
      return ((0.15 * this.marks.pres) + (0.15 * this.marks.gd) + (0.1 * this.marks.lab) + (0.1 * this.marks.ha) + (0.1 * this.marks.cp) + (0.2 * this.marks.cvv) + (this.marks.mse / 3) + (this.marks.ese / 3)).toFixed(2);
    }
  },
  methods: {
    copyToClipboard(){
      let that = this;
      navigator.clipboard.writeText("https://marks.amolbohora.com/?load=" + JSON.stringify(this.marks)).then(function() {
        that.text = "Link Copied"
      }, function() {
        that.text = "Something Went Wrong"
      });
      this.snackbar = true;
    },
    save() {
      if (this.$refs.form.validate()){
        this.dialog = false;
        let data = JSON.parse(localStorage.getItem("Data"))
        if (data === null)
          data = {}
        data[this.subjectName] = (this.marks)
        localStorage.setItem("Data", JSON.stringify(data))
        this.items.push({ text: this.subjectName, frontIcon: 'mdi-briefcase-upload', backIcon: 'mdi-delete-forever' })
        this.rawData[this.subjectName] = this.marks;
        this.subjectName = "";
      }
    },
    deleteSubject(i){
      this.selectedItem = i;
      console.log(this.selectedItem);
      let subjectName = this.items[this.selectedItem]['text']
      if (subjectName === 'None')
        return;
      this.items.splice(this.selectedItem, 1);
      delete this.rawData[subjectName];
      localStorage.setItem("Data", JSON.stringify(this.rawData))
    },
    loadSubject(i){
      this.selectedItem = i;
      // window.location = "https://marks.amolbohora.com/?load=" + JSON.stringify(this.rawData[this.items[this.selectedItem]['text']])
      this.marks = this.rawData[this.items[this.selectedItem]['text']];
    },
  },
  mounted() {
    let data = JSON.parse(localStorage.getItem("Data"))
    if (data === null)
      return;
    this.rawData = data;
    for(const [key] of Object.entries(data)) {
      this.items.push({ text: key, frontIcon: 'mdi-briefcase-upload', backIcon: 'mdi-delete-forever' })
    }
    let loadData = JSON.parse(new URL(location.href).searchParams.get('load'))
    if (loadData !== null)
      this.marks = loadData;
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  line-height: normal;
}

.InputData {
  max-width: 50vh;
  margin: auto;
}

@media only screen and (max-width: 1300px) {
  #app {
    width: 100%;
    padding: 10px;
  }

  .InputData {
  }
}

</style>

